.hightlight_box {
    flex: 1;
    height: 15.6vw;
    color: #000000;
    position: relative;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 25px;
    padding: 1.66vw 2.08vw;
    user-select: none;
    transition: all 0.5s;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.icon {
    position: absolute;
    top: -20px;
    right: -10px;
}

.single_box {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
}

.title_box {
    margin-top: 10px;
}

.title {
    font-size: 1vw;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.btn {
    width: 7.81vw;
    height: 2.6vw;
    border-radius: 25px;
    opacity: 1;
    cursor: pointer;
    background: #FFFFFF;
    line-height: 2.6vw;
    margin-top: 20px;
    text-align: center;
    font-weight: bold;
}

.subtitle {
    margin-top: 5px;
    opacity: 0.4;
    font-size: 0.78vw;
    font-weight: 500;
    letter-spacing: 0px;
}


.imgbox {
    width: 6.25vw;
    height: 6.25vw;
    background-size: 100% 100%;
}

.content_box {
    font-size: 0.73vw;
    font-weight: 600;
    letter-spacing: 0px;

}

.inline {
    display: inline;
}

@media (max-width: 1366px) {
    .icon {
        max-width: 80px;
    }
}

@media (max-width: 820px) {
    .hightlight_box {
        flex: none;
        height: 54.66vw;
        width: calc((100% - 10px) / 2);
    }

    .imgbox {
        width: 21.33vw;
        height: 21.33vw;
    }

    .btn {
        font-size: 3.2vw;
        height: 10.66vw;
        line-height: 10.66vw;
        width: 32vw;
    }

    .icon {
        max-height: 60px;
        height: 16vw;
        top: -5px;
        right: -5px;
    }


    .title {
        font-size: 3.2vw;
    }
}