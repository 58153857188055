.hightlight_box {
  flex: 1;
  height: 13vw;
  color: #000000;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  padding: 1.66vw 2.08vw;
  user-select: none;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.course_box:hover {
  background: #FFA516;
  color: #fff;
}

.single_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title_box {}

.title {
  font-size: 1.56vw;
  font-weight: bold;
  letter-spacing: 0px;
}

.subtitle {
  margin-top: 5px;
  opacity: 0.4;
  font-size: 0.78vw;
  font-weight: bold;
  letter-spacing: 0px;
}


.imgbox {
  width: 5.2vw;
  height: 5.2vw;
  background-size: 100% 100%;
  transition: all 0.5s;
}

.imgbox img {
  width: 100%;
}

.content_box {
  font-size: 0.73vw;
  width: 100%;
  font-weight: 600;
  letter-spacing: 0px;
  margin-top: 10px;
}

.content_box div p {
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
}

.inline {
  display: inline;
}

@media(max-width:1366px) {
  .hightlight_box {
    padding: 0.66vw 2.08vw;
    border-radius: 15px;
  }
}

@media (max-width: 820px) {


  .hightlight_box {
    flex: auto;
    width: 100%;
    border-radius: 3.2vw;
    height: 42.3vw;
    background: rgba(0, 0, 0, 0.05) !important;
    color: #000 !important;
    padding: 5.86vw 4vw;
  }

  .title {
    font-size: 4.26vw;
  }

  .subtitle {
    color: #FFA516 !important;
    opacity: 1 !important;
    font-size: 2.67vw;
  }

  .imgbox {
    width: 10.67vw;
    height: 10.67vw;
  }

  /* .content_box {
    display: block !important;
  } */

  .inline {
    font-size: 2.67vw;
  }
}