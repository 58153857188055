.box {
  flex: 1;
  height: 5.2vw;
  margin-top: 1vw;
  color: #000000;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  padding: 1.32vw 1vw;
  user-select: none;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.course_box:hover {
  background: #FFA516;
  color: #fff;
}

.single_box {
  width: 100%;
  display: flex;
  align-items: center;
}

.title_box {
  margin-left: 10px;
  width: calc(100% - 45px);
}

.title {
  font-size: 1vw;
  font-weight: bold;
  letter-spacing: 0px;
}



.subtitle {
  font-size: 0.625vw;
  font-weight: 500;
  letter-spacing: 0px;
  opacity: 0.6;
}


.imgbox {
  width: 45px;
  height: 45px;
  background-size: 100% 100%;
  transition: all 0.5s;
}

.imgbox img{
  width: 100%;
}

.content_box {
  font-size: 0.73vw;
  font-weight: 600;
  letter-spacing: 0px;
}

.inline {
  display: inline;
}

@media (max-width:1366px) {
  .imgbox {
    height: 30px;
    width: 30px;
  }

  .title_box {
    width: calc(100% - 30px);
  }
}

@media (max-width: 820px) {
  .box {
    height: 21.3vw;
    margin-top: 10px;
    padding: 0 4vw;
  }

  .title_box {
    width: calc(100% - 14.27vw);
  }

  .title {
    font-size: 4.27vw;
    line-height: 4.27vw;
  }

  .imgbox {
    height: 10.66vw;
    width: 10.66vw;
  }

  .subtitle {
    margin-top: 2px;
    font-size: 2.67vw;
  }
}