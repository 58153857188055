.modal-card {
    border-radius: 30px;
}

.modal-card-head,
.modal-card-foot {
    align-items: center;
    background-color: #f5f5f5;
    display: flex;
    flex-shrink: 0;
    justify-content: flex-start;
    padding: 20px;
    position: relative;
}

.modal-card-head {
    font-weight: bold;
    color: #000;
    background: #fff;
    border-bottom: 0px;
    padding: 30px 30px 0;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
}

.modal-card-title {
    color: #363636;
    flex-grow: 1;
    flex-shrink: 0;
    font-size: 1.5rem;
    line-height: 1;
}

.modal-card-head .logo {
    width: 30px;
    height: 30px;
    margin-right: 8px;
}

.tabs {
    margin-bottom: 30px;
}

.tabs .icon {
    width: 24px;
    height: 24px;
}

.tabs ul {
    align-items: center;
    border-bottom-color: #dbdbdb;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    display: flex;
    flex-grow: 1;
    flex-shrink: 0;
    justify-content: flex-start;
}

.tabs li {
    display: block;
}



.tabs a {
    align-items: center;
    border-bottom-color: #dbdbdb;
    border-bottom-style: solid;
    border-bottom-width: 0px;
    color: #999;
    display: flex;
    justify-content: center;
    margin-bottom: 0px;
    padding: 0.5em 1em;
    vertical-align: top;
    font-weight: 700;
    font-size: 18px;
    padding: 0.5em 0 !important;
    margin-right: 90px;
}

.tabs a span {
    font-size: 18px;
}

.tabs a {
    color: #000;
}

.tabs .icon:first-child {
    margin-right: 0.5em;
}


.icon {
    align-items: center;
    display: inline-flex;
    justify-content: center;
    height: 1.5rem;
    width: 1.5rem;
}

.is-flex {
    display: flex !important;
}

.is-align-items-center {
    align-items: center !important;
}

.is-flex-direction-row {
    flex-direction: row !important;
}

.sub-btn {
    width: 100%;
    height: 70px;
    margin-top: 20px;
    border-radius: 16px;
    font-size: 20px;
    font-weight: bold;
}

.field {
    margin-bottom: 30px;
}

.control {
    box-sizing: border-box;
    clear: both;
    font-size: 1rem;
    position: relative;
    text-align: inherit;
}

.field.is-grouped {
    display: flex;
    justify-content: flex-start;
}

.field.is-grouped>.control {
    flex-shrink: 0;
}

.field.is-grouped>.control.is-expanded {
    flex-grow: 1;
    flex-shrink: 1;
}

.field.is-grouped>.control:not(:last-child) {
    margin-bottom: 0;
    margin-right: 0.75rem;
}

.button.is-black {
    background-color: #0a0a0a;
    border-color: transparent;
    color: #fff;
}

.input {
    font-family: "SimHei", "黑体";
    font-weight: 450;
    border-radius: 16px;
    font-size: 16px;
    font-weight: bold;
    background-color: #f1f1f1;
    border: 0px;
    box-shadow: none;
    height: 70px;
    padding: 0 30px;
}

 /* 隐藏Chrome、Safari、Edge中的上下箭头 */  
 input[type="number"]::-webkit-inner-spin-button,  
 input[type="number"]::-webkit-outer-spin-button {  
   -webkit-appearance: none;  
   margin: 0;  
 }  
 
 /* 隐藏Firefox中的上下箭头 */  
 input[type="number"] {  
   -moz-appearance:textfield;  
 }  

.textarea,
.input {
    max-width: 100%;
    width: 100%;
}

.password_field {
    position: relative;
}

.password_field .eye {
    position: absolute;
    right: 30px;
    top: calc(50% - 12px);
    width: 24px;
    height: 24px;
    cursor: pointer;
}

.sendcode {
    height: 70px;
    padding: 0 50px;
    border-radius: 16px;
    margin-left: 15px;
    font-size: 18px;
}

.stay-login {
    font-family: "SimHei", "黑体";
    font-weight: 450;
    color: #999;
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.stay-login:hover .checkbox {
    color: #999;
}

.stay-login input {
    position: absolute;
    opacity: 0;
    height: 0;
    width: 0;
}

.stay-login label {
    position: relative;
    display: block;
    padding-left: 30px;
    cursor: pointer;
    padding-top: 5px;
}

.stay-login label:before {
    content: "";
    position: absolute;
    top: 50%;
    margin-top: -10px;
    left: 0;
    display: block;
    height: 20px;
    width: 20px;
    background-color: #f1f1f1;
    border-radius: 6px;
    border: 1px solid #dbdbdb;
}

.stay-login label:after {
    content: "";
    position: absolute;
    left: 7px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    background-color: #ffa516;
    opacity: 0;
}

.stay-login input:checked+label:after {
    opacity: 1;
}

.stay-login input:checked+label:before {
    background-color: #ffa516;
    border-color: #ffa516;
}

.login-text {
    font-weight: 450;
    margin: 30px 50px;
    text-align: center;
    color: #999;
}

.login-text a {
    display: inline;
    color: #ffa516;
}

.button {
    transition: all 0.3s;
    font-family: "SimHei", "黑体";
    font-weight: 450;
    cursor: pointer;
}

.button.is-black[disabled] {
    background-color: #f1f1f1;
    border-color: transparent;
    color: #999999;
    font-weight: bold;
    opacity: 1;
    font-family: "SimHei", "黑体";
    font-weight: 450;
}

.modal-card-body {
    -webkit-overflow-scrolling: touch;
    background-color: #fff;
    flex-grow: 1;
    flex-shrink: 1;
    overflow: auto;
    padding: 20px;
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
}

.yellow {
    color: #ffa516 !important;
}

.button[disabled],
fieldset[disabled] .button {
    background-color: #fff;
    border-color: #dbdbdb;
    box-shadow: none;
    opacity: .5;
}

.button.is-primary[disabled],
fieldset[disabled] .button.is-primary {
    background-color: #ffa516;
    border-color: transparent;
    box-shadow: none;
}

.button.is-primary:hover,
.button.is-primary.is-hovered {
    background-color: #ffa009;
    border-color: transparent;
    color: #fff;
}

.modal .sub-btn[data-v-350192e5] {
    width: 100%;
    height: 70px;
    margin-top: 20px;
    border-radius: 16px;
    font-size: 20px;
    font-weight: 700;
}

[disabled].button {
    cursor: not-allowed;
}

.button.is-primary {
    background-color: #ffa516;
    border-color: transparent;
    color: #fff;
}

.ant-message-notice-content span {
    font-size: 12px;
}

@media (max-width: 1366px) {
    .modal-card-title {
        font-size: 3.5rem;
    }
    .login-text {
        font-size: 12px;
        margin: 15px 25px;
    }

    .login-text a,
    .stay-login label {
        font-size: 12px;
    }
    .sendcode {
        font-size: 14px;
    }
}

@media (max-width: 820px) {
    .modal-card-title {
        font-size: 6.5rem;
    }

    .tabs a {
        margin-right: 10px;
    }

    .login-text {
        margin: 15px 25px;
    }


    .field {
        margin-bottom: 10px;
    }

    .stay-login {
        margin-top: 15px;
    }

    .field.is-grouped>.control.is-expanded {
        flex: 2;
    }

    .sendcodep {
        flex: 1;
    }

    .input {
        padding: 0 15px;
    }

    .sendcode {
        padding: 0;
        width: calc(100% - 15px);
    }
}