.hightlight_box {
  flex: 1;
  height: 13vw;
  color: #000000;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  padding: 1.67vw 2.08vw;
  user-select: none;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.course_box:hover {
  background: #FFA516;
  color: #fff;
}

.single_box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.title_box {}

.title {
  font-size: 1.56vw;
  font-weight: bold;
  letter-spacing: 0px;
}



.subtitle {
  margin-top: 5px;
  opacity: 0.4;
  font-size: 0.78vw;
  font-weight: 500;
  letter-spacing: 0px;
}


.imgbox {
  width: 5.2vw;
  height: 5.2vw;
  background-size: 100% 100%;
  transition: all 0.5s;
}

.content_box {
  font-size: 7.3vw;
  font-weight: 600;
  letter-spacing: 0px;

}

.inline {
  display: inline;
}

@media (max-width:1366px) {
  .hightlight_box {
    border-radius: 15px;
  }
}

@media (max-width: 820px) {
  .hightlight_box {
    flex: none;
    width: 100%;
    height: 21.33vw;
    border-radius: 15px;
    padding: 4vw;
  }

  .imgbox {
    height: 10.67vw;
    width: 10.67vw;
  }

  .title {
    height: 5.6vw;
    line-height: 5.6vw;
    font-size: 4vw;
  }

  .subtitle {
    font-size: 2.6vw;
    line-height: 2.6vw;
  }
}