.course_box {
    flex: 1;
    color: #fff;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    min-height: 250px;
    display: flex;
    position: relative;
    justify-content: space-between;
    transition: all 0.5s;
    
}

.course_box:hover {
    background: #FFFFFF;
    color: #000;
}

.course_box .leftbox {
    width: 50%;
}

.course_box img {
    width: 47.85%;
    position: absolute;
    right: 0;
    bottom: 0;
}

.title {
    margin-top: 2.08vw;
    margin-left: 2.08vw;
}

.title div {
    font-size: 2.08vw;
    font-weight: bold;
    letter-spacing: 0px;
}

.subtitle {
    margin-left: 2.08vw;
    margin-top: 1vw;
    opacity: 0.7;
    margin-bottom: 1vw;
}


.subtitle div {
    font-size: 0.78vw;
    font-weight: 500;
    letter-spacing: 0px;
}

.img_box {
    position: relative;
}

.btn_box {
    bottom: 0;
    width: 100%;
    height: calc(25px + 2.6vw);
}

.btn {
    bottom: 25px;
    left: 50%;
    width: 44%;
    margin: 0 auto 0 2.08vw;
    height: 2.6vw;
    line-height: 2.6vw;
    border-radius: 1.3vw;
    background-color: #fff;
    font-size: 0.73vw;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    transition: all 0.5s;
    user-select: none;
    cursor: pointer;
}

.btn:hover {
    background: #FFA516;
    width: 82%;
}


.price {
    /* color: rgb(200, 0, 0); */
    font-weight: bold;
    font-size: 0.8vw;
    margin-top: 8px;
}

@media (max-width: 1366px) {
    .course_box {
        position: relative;
        border-radius: 15px;
        min-height: 170px;
    }

    .img_box {
        margin: 0 2.6vw;
        bottom: 0;
    }

    .btn{
        margin: 0;
    }
}


@media (max-width: 820px) {

    .course_box {
        flex: none;
        width: 39.67vw;
        border-radius: 15px;
        position: relative;
        height: 69vw;
    }

    .title {
        margin-top: 5.3vw;
        margin-left: 4vw;
    }

    .title div {
        height: 6.67vw;
        line-height: 6.67vw;
        font-size: 4.8vw;
    }

    .subtitle {
        margin-left: 4vw;
        margin-top: 2.6vw;
    }

    .subtitle p {
        font-size: 2.67vw;
    }

    .btn {
        width: 100%;
        height: 9.3vw;
        line-height: 9.3vw;
        border-radius: 4.6vw;
        font-size: 3.5vw;
    }

    .btn_box {
        height: 12vw;
    }

    .btn:hover {
        width: 100%;
    }



    .btn_border {
        height: 2.6vw;
        margin-left: -2.6vw;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        width: calc(100% + 20px);
    }

    .btn:hover+.btn_border {
        opacity: 1;
        margin-left: -10px;
    }
}