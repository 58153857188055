.hightlight_box {
  display: inline-block;
  height: 350px;
  width: 400px;
  user-select: none;
}

.single_box {
  width: 100%;
}

.teacher img {
  width: 100%;
  position: absolute;
  bottom: 160px;
  border-radius: 10px;
}

.title_box {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 15px;
  height: 160px;
  opacity: 1;
  background: rgba(255, 255, 255, 0.1);
}

.title {
  font-size: 1.56vw;
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFA516;
}

.subtitle {
  margin-top: 4px;
  font-size: 0.73vw;
  font-weight: 500;
  letter-spacing: 0px;

  color: #666;
}


.content {
  margin-top: 10px;
  opacity: 0.4;
  font-size: 0.625vw;
  font-weight: 500;
  letter-spacing: 0px;

  color: #D8D8D8;
}

@media (max-width:1366px) {
  .hightlight_box {
    height: 310px;
  }
}

@media (max-width: 820px) {
  .hightlight_box {
    height: 72vw;
    width: 40.66vw;
  }

  .title_box {
    height: 32vw;
  }

  .title {
    font-size: 4.8vw;
  }

  .subtitle {
    font-size: 2.6vw;
  }

  .teacher img {
    bottom: 32vw;
  }

}