.course_box {
    width: calc((100% - 60px) / 4);
    color: #fff;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 30px;
    transition: all 0.5s;
    position: relative;
    height: 29vw;
}

.cfacourse_box {
    width: calc((100% - 60px) / 4);
    color: #000;
    background-color: #F6F6F6;
    border-radius: 30px;
    transition: all 0.5s;
    position: relative;
    height: 29vw;
}

.course_box:hover {
    background: #FFFFFF;
    color: #000;
}

.cfacourse_box:hover {
    background: #FFA516;
    color: #fff;
}

.title {
    margin-top: 2.08vw;
    margin-left: 2.08vw;
}

.title div {
    font-size: 2.08vw;
    line-height: 2.5vw;
    font-weight: bold;
    letter-spacing: 0px;
}

.cfacourse_box .title div {
    line-height: 2.5vw;
}

.subtitle {
    margin-left: 2.08vw;
    margin-top: 1vw;
    opacity: 0.7;
    margin-bottom: 1vw;
}


.subtitle div {
    font-size: 0.78vw;
    font-weight: 500;
    letter-spacing: 0px;
}

.img_box {
    position: absolute;
    bottom: 0;
    margin: 0 20px;
}

.img_box img {
    width: 100%;
    vertical-align: bottom;
}

.btn_box {
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(25px + 2.6vw);
}

.btn {
    bottom: 25px;
    left: 50%;
    width: 44%;
    margin: 0 auto;
    height: 2.6vw;
    line-height: 2.6vw;
    border-radius: 1.3vw;
    background-color: #fff;
    font-size: 0.73vw;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0px;
    color: #000000;
    transition: all 0.5s;
    user-select: none;
    cursor: pointer;
}

.btn:hover {
    background: #FFA516;
    width: 82%;
}

.btn_border {
    opacity: 0;
    width: calc(100% + 40px);
    height: 25px;
    margin-left: -20px;
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.00) 0%, #FFFFFF 100%);
    border-bottom-left-radius: 30px;
    border-bottom-right-radius: 30px;
    transition: all 0.5s;
}

.cfacourse_box .btn_border{
    background: none;
}



.btn:hover+.btn_border {
    opacity: 1;
    margin-left: -20px;
}

.price {
    /* color: rgb(200, 0, 0); */
    font-weight: bold;
    font-size: 0.8vw;
    margin-top: 8px;
}

@media (max-width: 1366px) {

    .course_box,
    .cfacourse_box {
        position: relative;
        height: 27vw;
        border-radius: 15px;
    }

    /* .cfacourse_box {
        height: 32vw;
    } */



    .img_box {
        margin: 0 2.6vw;
        position: absolute;
        bottom: 0;
    }

    .cfacourse_box .btn_border {
        width: calc(100% + 5.2vw);

    }

    .cfacourse_box .btn:hover+.btn_border {
        margin-left: -2.6vw;
    }
}


@media (max-width: 820px) {

    .course_box,
    .cfacourse_box {
        flex: none;
        width: calc((100% - 2.67vw) / 2);
        border-radius: 15px;
        position: relative;
        height: 76vw;
    }

    .title {
        margin-top: 5.3vw;
        margin-left: 4vw;
    }

    .title div {
        height: 6.67vw;
        line-height: 6.67vw;
        font-size: 4.8vw;
    }

    .subtitle {
        margin-left: 4vw;
        margin-top: 2.6vw;
    }

    .subtitle p {
        font-size: 2.67vw;
    }

    .btn {
        width: 100%;
        height: 9.3vw;
        line-height: 9.3vw;
        border-radius: 4.6vw;
        font-size: 3.5vw;
    }

    .btn_box {
        height: 12vw;
    }

    .btn:hover {
        width: 100%;
    }



    .btn_border {
        height: 2.6vw;
        margin-left: -2.6vw;
        border-bottom-left-radius: 15px;
        border-bottom-right-radius: 15px;
        width: calc(100% + 20px);
    }

    .btn:hover+.btn_border {
        opacity: 1;
        margin-left: -10px;
    }
}