/* @font-face {
  font-family: 'yuanti';
  src:
    url('../src/assets/font/yuanti.woff') format('woff'),
    url('../src/assets/font/yuanti.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
} */

* {
    font-family: "PingFang SC", "Helvetica Neue", Helvetica, 'Hiragino Sans GB', 'Microsoft Yahei', '微软雅黑', Arial, sans-serif;
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-size: 0.625vw;
}

html {
    background-color: #F6F6F6;
}

ul {
    list-style: none;
}

a {
    text-decoration: none;
    color: #333;
}

a:hover,
a:visited,
a:link,
a:active {
    color: #333;
}


.header-box {
    width: 80%;
    margin: 0 auto;
    padding: 0 3.125vw;
    max-width: 1540px;
}

.container {
    width: 80vw;
    margin: 0 auto;
    max-width: 1540px;
    padding: 3.125vw;
    border-radius: 3.125vw;
}

.App-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.73vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #141623;
    height: 80px;
}

.logout {
    position: absolute;
    right: calc(10% + 3.125vw);
    top: 55px;
    cursor: pointer;
    width: 80px;
    height: 40px;
    background: #fff;
    border-radius: 5px;
    text-align: center;
    line-height: 40px;
    font-size: 14px;
    box-shadow: 0 3px 8px #0a0a0a1a;
}

.title-box {
    display: flex;
}

.title-btn-box {
    display: flex;
    align-items: center;
}

.title-box>div>a {
    font-size: 12px;
    margin-left: 2.1vw;

}

.login-btn-box {
    margin-left: 50px;
    display: inline-block;
}

.login-btn {
    padding: 10px;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #FFA516;
    gap: 8px;
    cursor: pointer;
}

.login-btn div {
    height: 18px;
    width: auto;
    line-height: 18px;
    color: white;
}

.login-btn div img {
    width: auto;
    height: 100%;
}

.top-box {
    height: 44.27vw;
    width: 100%;
    background-image: linear-gradient(rgba(255, 255, 255, 1), #F6F6F6);
}

.top-box>.container {
    border-radius: 0;
    display: flex;
    height: 100%;
    padding-bottom: 0;
}

.top-box>.container>.left-box {
    width: 38.3%;
    padding-top: 11vw;
    margin-right: 2.4vw;
}

.top-box>.container>.left-box>.banner-text {
    font-size: 1.5rem;
    font-weight: 400;
    color: rgba(0, 0, 0, .3);
    margin-top: 30px;
}

.top-box>.container>.left-box>.banner-text>span {
    font-size: inherit;
    font-weight: 600;
    color: #FFA516;
    margin-right: 3px;
}

.top-box>.container>.left-box>.slogan-btn {
    width: 7.8vw;
    height: 2.6vw;
    cursor: pointer;
    margin-top: 52px;
    border-radius: 25px;
    background: #1B1B18;
    color: #FFF;
    font-size: 0.73vw;
    font-weight: 500;
    text-align: center;
    letter-spacing: 0px;
    user-select: none;
    border: none;
}

.top-box>.container>.right-box {
    width: 50%;
    height: 100%;
    position: relative;
}


.top-box>.container>.right-box img {
    position: absolute;
    bottom: 0;
}

.top-box>.container img {
    width: 100%
}

.container .title {
    margin-top: 40px;
    font-size: 2.6vw;
    font-weight: bold;
    letter-spacing: 0px;
    user-select: none;
}

.container .title span {
    font-size: 2.6vw;
    height: 3.64vw;
    line-height: 3.64vw;
    font-weight: bold;
    letter-spacing: 0px;
    user-select: none;
}

.container .child_title {
    margin-top: 4.17vw;
    font-size: 1.56vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFA516;
}

.FFA516 {
    color: #FFA516;
}

.container .subtitle {
    font-size: 1.56vw;
    font-weight: bold;
    letter-spacing: 0px;
    user-select: none;
}

.container .child_subtitle {
    font-size: 0.93vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFA516;
}

.container .courses {
    margin-top: 3.125vw;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    row-gap: 20px;
}

.container .courses .hightlight_box {
    flex: 1;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 30px;
    height: 25vw;
    padding: 40px;
}

.container .courses .hightlight_box .title {
    margin-top: 0;
}

.container .courses .hightlight_box .subtitle {
    font-size: 0.78vw;
    font-weight: 500;
    letter-spacing: 0px;
    opacity: 0.4;
    color: #000000;
}

.container .courses .left_box img {
    width: 100%;
}

.container .courses .right_box img {
    width: calc(100% - 80px);
    position: absolute;
    bottom: 40px
}

.container .reasons {
    display: flex;
    margin-top: 1.56vw;
    gap: 30px;
}

.advantages_box {
    width: 80%;
    margin: 0 auto;
    max-width: 1540px;
    border-radius: 3.125vw;
}

.advantages_box .advantages {
    display: flex;
}

.advantages .left_box {
    flex: 3;
    margin-bottom: 2vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.advantages .left_box .title {
    margin-top: 5.2vw;
    margin-left: 2.1vw;
    font-size: 2.6vw;
    font-weight: bold;
    letter-spacing: 0px;
    -webkit-user-select: none;
    user-select: none;
}

.advantages .left_box .subtitle {
    margin-left: 2.1vw;
    font-size: 1.56vw;
    font-weight: bold;
    letter-spacing: 0px;
    -webkit-user-select: none;
    user-select: none;
}

.advantages .right_box {
    flex: 7;
}

.advantages .right_box img {
    width: 100%;
    height: 100%;
    border-top-right-radius: 3.125vw;
    border-bottom-right-radius: 3.125vw;
}


.teachers_box {
    margin-top: 40px;

}

.banner_box {
    height: 24.48vw;
    border-radius: 30px;
    padding-left: 3.125vw;
}

.banner_box .left_content {
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 27%;
    justify-content: center;
}

.banner_box .right_content {
    position: absolute;
    width: 56%;
    height: 100%;
    top: 0;
    right: 0;
}

.banner_box .right_content .content-img-wrap {
    height: 24.48vw;
}

.right_content .content-img-wrap img {
    width: 100%;
    height: 100%;
    display: block;
    padding: 16px;
    border-radius: 32px;
}

.right_content .btn-left {
    position: absolute;
    cursor: pointer;
    right: 80px;
    bottom: 30px;
}

.right_content .btn-right {
    position: absolute;
    cursor: pointer;
    right: 30px;
    bottom: 30px;
}

.banner_box .title {
    font-size: 1.82vw;
    font-weight: bold;
    letter-spacing: 0px;

    margin-top: 0;
}

.ant-carousel .slick-dots-bottom {
    bottom: 30px;
    left: 30px;
    justify-content: left;
}

.ant-carousel .slick-dots li button {
    width: 8px;
    height: 8px;
    border-radius: 50%;
}

.ant-carousel .slick-dots li.slick-active button {
    background-color: #FFA516;
}

.ant-carousel .slick-dots li button:hover {
    background-color: #FFA516;
}

.banner_box .subtitle {
    height: auto;
    font-size: 0.78vw;
    font-weight: normal;
    letter-spacing: 0px;
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.7);
}

.banner_box .subtitle span {
    color: #fff;
    font-size: 0.78vw;
}

.teachers .content {
    opacity: 1;
    font-size: 0.78vw;
    font-weight: 500;
    letter-spacing: 0px;
    max-width: 28.65vw;
    color: #666;
}

.teachers-row {
    width: calc(100% + 6.25vw);
    height: 10.42vw;
    margin-top: 2.6vw;
    margin-left: -3.125vw;
    background-position: 0;
    background-repeat: repeat-x;
    background-size: auto 100%;
    transition: background-position 5s linear;
}

.container .reasons div {
    flex: 1;
    transition: all 0.5s;
}

.container .reasons div:hover {
    margin-top: -10px;
}

.container .reasons div img {
    width: 100%;
}

.container .inset-img {
    position: absolute;
    right: 0;
    top: 0;
    width: 28.5%;
    border-top-right-radius: 3.125vw
}

.container .aboutus_box {
    display: flex;
    gap: 20px;
    margin-top: 30px;
}

.container .title_box {
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
}

.container .title_box .little_title {
    font-size: 1.56vw;
    font-weight: bold;
    letter-spacing: 0px;
    color: #000000;
}

.container .title_box .btn_box {
    display: flex;
    justify-content: space-between;
    width: 75px;
}

.container .title_box .btn_box img {
    cursor: pointer;
    vertical-align: bottom;
    width: 2.1vw;
    height: 2.1vw;
}

.container .history_box {
    margin-top: 20px;
    width: 100%;
}

.container .honors_box {
    margin-top: 50px;
    display: flex;
    height: 15.625vw;
}

.container .honors_box .honorsPic {
    width: 21.8vw;
}

.container .honors_box .honorsPic img {
    width: 100%;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}



.container .honors_box .honors {
    position: absolute;
    width: calc(100% - 25vw);
    height: 10.42vw;
    margin-top: 2.6vw;
    margin-left: 21.87vw;
    background-position: 0;
    background-repeat: repeat-x;
    background-size: auto 100%;
    transition: background-position 5s linear;
}

.partners {
    background: rgba(0, 0, 0, 0.05);
}

.partners_box,
.card_box {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 50px;
    justify-content: space-between;
    cursor: pointer;
}

.card_box {
    margin-top: 20px;
    flex-wrap: nowrap;
    gap: 10px;
}

.card_box img {
    width: 24%;
}

.partners_box img {
    width: calc((100% - 40px) / 3);
}

.partners .title,
.partners .subtitle {
    color: #14161E;
}

.container .resources {
    display: flex;
    margin-top: 30px;
    gap: 20px;
}

.btns {
    display: flex;
    margin-top: 30px;
    justify-content: center;
    gap: 20px;
}

.container .btns .download_btn {
    font-size: 1.04vw;
    font-weight: 600;
    text-align: center;
    letter-spacing: 0px;
    padding: 1.38vw 3.125vw;
    color: #FFFFFF;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 2.1vw;
    cursor: pointer;
    transition: all 0.3s;
}

.container .btns .download_btn:hover {
    background: rgba(255, 255, 255, 0.3);
}

.container .btns .download_btn:last-child {
    background-color: #FB731B;
}

.container .btns .download_btn:last-child:hover {
    background-color: #DF5903;
}

.foot {
    margin-top: 100px;
    padding: 15px 0;
}

.foot .btns {
    margin-top: 0;
}

.foot_qrcode {
    width: 9.9vw;
    position: absolute;
    bottom: 55px;
    left: 50%;
    margin-left: -5vw;
}

.l35 {
    left: 35px
}

.copyright {
    user-select: none;
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0px;
    opacity: 0.2;
    color: #14161E;
    margin: 30px 0;
    text-align: center;
}

.copyright span {
    font-size: 12px;
}

.copyright span:first-of-type,
.copyright span:nth-child(2) {
    margin-right: 20px;
}

.container .appbox {
    border-radius: 35px;
    width: 100%;
    padding: 3.125vw;
    display: flex;
    justify-content: space-between;
}

.container .appbox>div {
    width: 50%;
}

.event-btn {
    width: 7.812vw;
    height: 2.6vw;
    border-radius: 1.3vw;
    opacity: 1;
    cursor: pointer;
    /* 揽辰黄 */
    text-align: center;
    line-height: 2.6vw;
    font-size: 0.73vw;
    font-weight: 500;
    letter-spacing: 0px;
    color: #FFFFFF;
    background: #FFA516;
}

.event-btn-qrcode {
    position: absolute;
    bottom: 0;
    right: -10.4vw;
    height: 250px !important;
}

.mt70 {
    margin-top: 70px;
}

.ant-modal .ant-modal-content {
    background-color: inherit;
    box-shadow: none;
    padding: 0;
}

.menu {
    display: none;
}

.container .appbox .app-hightlight img {
    width: 35.3vw;
    height: 7.8125vw !important;
}

.bg005 .left_box {
    padding: 5.2vw 0;
}

.appbox .left_box>div:first-of-type {
    display: flex;
    gap: 30px;
    row-gap: 5px;
    justify-content: space-between;
}

.ant-modal-footer {
    display: none;
}



.modal_box img {
    width: 100%;
    vertical-align: middle;
}

.appbox .left_box .title_box {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 0;
    justify-content: center;
}

.appbox .left_box .title {
    font-size: 1.56vw;
    margin-top: 0;
    height: auto;
    line-height: 40px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFFFFF;
}

.appbox .left_box .subtitle {
    font-size: 0.78vw;
    height: auto;
    line-height: 20px;
    font-weight: bold;
    letter-spacing: 0px;
    color: #FFA516;
}

.appbox .left_box .content {
    font-size: 0.78vw;
    font-weight: 500;
    letter-spacing: 0px;

    color: #666;
}

.appbox .left_box:first-of-type img {
    height: 150px;
}

.appbox .right_box {
    padding: 0 3.125vw;
    position: relative;
}



.bg005 .event-btn {
    background: #1B1B18;
    color: #FFFFFF;
}

.appbox .right_box img {
    width: 68%;
    vertical-align: bottom;
    position: absolute;
    bottom: -3.125vw;
    right: 3.125vw;
}

.bg005 {
    background: rgba(0, 0, 0, 0.05);
}


.bgFFF {
    background-color: #fff;
    position: fixed;
    width: 100%;
    z-index: 999;
}

.bgFFA516 {
    background-color: #FFA516;
}

.bg14161E {
    background-color: #14161E;
}


.bg141623 {
    background-color: #141623;
}

.bgFFFFFF {
    background-color: #FFF;
}

.bgFFA516,
.bg14161E>.title,
.bgFFA516,
.bg14161E>.subtitle {
    color: #FFFFFF;
}


.bgFFFFFF>.title,
.bgFFFFFF>.subtitle {
    color: #000;
}

.relative {
    position: relative;
}

.mt100 {
    margin-top: 100px;
}

.mt60 {
    margin-top: 3.125vw;
}

.mt50 {
    margin-top: 50px;
}

.mt40 {
    margin-top: 40px;
}

.mt20 {
    margin-top: 20px;
}

.w100 {
    width: 100%;
}

.pd40 {
    padding: 0 40px;
}

.bg005 .title_box .title {
    color: #14161E;
}

.pc {
    display: flex !important;
}

.mobile {
    display: none !important;
}

.container .title_box .little_subtitle {
    display: none;
}

.mobile-login {
    display: none;
}


.detail-top-box {
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: 0 auto;
    padding-top: 130px;
}

.detail-top-box>.left_content {
    width: 47.5%;
}

.detail-top-box>.left_content .content-img-wrap img {
    border-radius: 40px;
    display: block;
    height: 100%;
    width: 100%;
}

.detail-top-box>.right_content {
    width: 47.5%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px 0;
}

.detail-top-box>.right_content>.title-box {
    flex-direction: column;
}

.detail-top-box>.right_content>.title-box>.title {
    font-weight: 600;
    font-size: 40px;
    color: #14161E;
    margin-bottom: 30px;
}

.detail-top-box>.right_content>.title-box>.price {
    font-weight: 800;
    font-size: 50px;
    color: #14161E;
}

.detail-top-box>.right_content>.btn {
    width: 390px;
    height: 80px;
    line-height: 80px;
    background: #000000;
    border-radius: 20px;
    font-weight: bold;
    font-size: 24px;
    color: #FFFFFF;
    text-align: center;
}

.detail-content{
    width: 80%;
}

@media (max-width:1366px) {
    .mt100 {
        margin-top: 50px;
    }

    .title-box>a {
        margin-left: 14px;
    }

    .banner_box {
        border-radius: 15px;
    }

    .container {
        padding: 2.125vw;
    }

    .container .courses .hightlight_box {
        border-radius: 15px;
    }

    .top-box>.container>.left-box>.slogan-btn {
        margin-top: 35px;
    }

    .pd40 {
        padding: 0 20px;
    }

    .teachers-row {
        width: calc(100% + 4.25vw);
        margin-left: -2.125vw;
        height: 8.42vw;
    }
}

@media (max-width: 920px) {
    .top-box {
        height: 47vw;
    }

    .login-btn-box {
        margin-left: 0;
    }
}

@media (max-width: 820px) {
    .logout {
        top: 45px;
        right: 10%;
    }

    .teachers-row {
        height: 12.42vw;
    }

    .copyright span:first-of-type,
    .copyright span:nth-child(2) {
        margin: 0;
    }

    #logo {
        height: 28px;
    }

    .container .reasons div:hover {
        margin-top: 0;
    }

    .advantages .left_box {
        margin-bottom: 15px;
    }

    .pc {
        display: none !important;
    }

    .header-box {
        width: auto;
        padding: 15px 25px;
    }

    .mobile {
        display: flex !important;
    }

    .App-header {
        height: 30px;
    }

    .App-header img {
        height: 30px;
    }

    .menu {
        display: block;
    }

    .title-box {
        display: none;
    }

    .top-box {
        height: 147.27vw;
    }

    .container {
        width: 92%;
        padding: 4vw;
        border-radius: 25px;
    }


    .top-box>.container {
        flex-direction: column;
    }

    .top-box>.container>.left-box {
        width: 78%;
        margin: 2vw auto 0 auto;
    }

    .banner_box .left_box .subtitle {
        font-size: 2.6vw;
    }

    .top-box>.container>.left-box>.slogan-btn {
        display: none;
    }

    .top-box>.container>.left-box>.banner-text {
        font-size: 9px;
        font-weight: 400;
        color: rgba(0, 0, 0, .3);
        margin-top: 15px;
    }

    .top-box>.container>.right-box {
        width: 100%;
    }

    .container .title {
        margin-top: 2.67vw;
        font-size: 5.33vw;
    }

    .container .child_title {
        font-size: 4vw;
        line-height: 4vw;
    }

    .container .child_subtitle {
        font-size: 2.6vw;
        line-height: 2.6vw;
    }

    .container .subtitle {
        font-size: 3.2vw;
    }

    .container .courses {
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 2.67vw;
    }

    .container .cfacourses {
        justify-content: center;
    }

    .container .reasons {
        gap: 2.67vw;
        flex-wrap: wrap;
        margin-top: 4vw;
    }

    .container .reasons div {
        flex: auto;
        width: calc(50% - 1.34vw);

    }

    .mt100 {
        margin-top: 8vw;
    }

    .container .courses .hightlight_box {
        flex: none;
        width: 100%;
        height: 57.6vw;
        padding: 5.87vw 4vw;
    }

    .container .title span {
        font-size: 5.33vw;
        font-weight: bold;
        letter-spacing: 0px;
        user-select: none;
    }

    .container .courses .hightlight_box .subtitle {
        color: #FFA516;
        opacity: 1;
        font-size: 2.6vw;
    }

    .container .courses .right_box img {
        width: calc(100% - 8vw);
        bottom: 4vw
    }

    .advantages_box {
        width: 92%;
    }

    .advantages .right_box {
        display: none;
    }

    .advantages .left_box .title {
        margin-top: 6.67vw;
        font-size: 5.33vw;
        font-weight: bold;
        margin-left: 4vw;
    }

    .advantages .left_box .subtitle {
        font-size: 3.2vw;
        margin-left: 4vw;
        line-height: 3.2vw;
        height: 3.2vw;
    }

    .teachers .content {
        width: 100%;
        max-width: 100%;
        margin-top: 5.33vw;


    }

    .teachers .content span {
        font-size: 2.67vw;
        line-height: 2.67vw;
    }

    .teachers_box {
        margin-top: 5.56vw;
        flex-wrap: wrap;
    }

    .banner_box {
        height: 92.9vw;
        padding: 5.86vw 4vw;
        border-radius: 15px;
    }

    .banner_box .left_content {
        width: 100%;
        justify-content: flex-start;
    }

    .banner_box .title {
        height: 5.33vw;
        margin-top: 6px;
    }

    .banner_box .right_content {
        width: 100%;
        height: 50%;
        bottom: 4vw;
        top: auto;
    }

    .banner_box .right_content .content-img-wrap {
        height: 50%;
    }

    .right_content .btn-left {
        display: none;
    }

    .right_content .btn-right {
        display: none;
    }

    .banner_box .subtitle {
        margin-top: 4vw;
        font-size: 2.6vw;
    }

    .container .aboutus_box {
        margin-top: 10px;
        gap: 10px;
        flex-wrap: wrap;
    }

    #about {
        overflow: hidden;
    }

    .swiper {
        overflow: inherit;
    }

    .container .title_box {
        flex-wrap: wrap;
        margin-top: 13.33vw;
    }


    .container .title_box .btn_box {
        display: none;
    }

    .container .title_box .little_title {
        width: 100%;
        font-size: 4.8vw;
    }

    .container .title_box .little_subtitle {
        display: block;
        color: #FFA516;
        font-weight: bold;
        font-size: 2.6vw;
    }

    .container .honors_box {
        flex-wrap: wrap;
        height: 59.33vw;
        margin-top: 13vw;
    }



    .container .honors_box .honors {
        width: 100%;
        margin-left: -4vw;
        height: 26.67vw;
        margin-top: 28vw;
    }

    .container .honors_box .honorsPic {
        width: 100%;
    }

    .container .honors_box .honorsPic img {
        top: 10vw;
    }

    .container .appbox {
        border-radius: 5.33vw;
        flex-wrap: wrap;
        flex-direction: column;
    }

    .container .appbox>div {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .appbox .left_box>div:first-of-type {
        flex-direction: column;
        text-align: center;
    }

    .appbox .left_box:first-of-type img {
        height: 16vw;
    }

    .container .appbox .app-hightlight img {
        width: 100%;
        height: 56vw !important;
    }

    .event-btn {
        width: 40vw;
        height: 13.33vw;
        border-radius: 6.666vw;
        margin: 8vw auto 0 auto;
        font-size: 3.73vw;
        line-height: 13.33vw;
    }

    .appbox .right_box {
        height: 74vw;
    }

    .bg005 .right_box {
        height: 56vw;
    }

    .appbox .left_box .title_box {
        gap: 0;
    }

    .appbox .right_box img {
        right: none;
        margin-left: -34%;
        left: 50%;
    }

    .appbox .left_box .title {
        font-size: 4.8vw;
        height: 6.66vw;
        line-height: 6.66vw;
    }

    .banner_box .subtitle span {
        font-size: 2.6vw;
    }

    .appbox .left_box .content {
        margin-top: 2.66vw;
        font-size: 2.66vw;
        line-height: 3.66vw;
    }

    .partners_box,
    .card_box {
        gap: 5px;
        margin-top: 5.33vw;
    }

    .card_box {
        margin-top: 10px;
    }

    .card_box {
        flex-wrap: wrap;
        gap: 10px;
    }

    .card_box img {
        width: 100%;
    }

    .partners_box img {
        width: calc((100% - 10px) / 3);
    }

    .container .resources {
        flex-wrap: wrap;
        gap: 10px;
    }

    .btns {
        margin-top: 15px;
        /* justify-content: space-between; */
    }

    .ant-dropdown {
        font-weight: 600;
        color: #141623;
        width: 140px;
        text-align: center;
        border-radius: 10px;
    }

    .head-title {
        font-weight: 600;
        font-size: 14px;
        line-height: 35px;
    }

    .ant-dropdown .ant-dropdown-menu {
        padding: 5px;
    }

    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item {
        padding: 0;
        margin: 5px;
        border-radius: 4px;
    }

    .ant-dropdown .ant-dropdown-menu .ant-dropdown-menu-item:hover {
        background: rgba(0, 0, 0, 0.04);
    }

    /* .btns:first-child {
    flex: 3;
    width: 40%;
  } */

    .btns:last-child {
        flex: 5;
    }

    .container .btns .download_btn {
        font-size: 4vw;
        height: 13.33vw;
        padding: 0;
        line-height: 13.33vw;
        border-radius: 6.66vw;
    }

    .container .btns .download_btn:first-child {
        width: 40%;
    }

    .container .btns .download_btn:last-child {
        width: 60%;
    }

    .foot {
        margin-top: 30px;
    }

    .foot .btns .relative:first-child {
        display: none;
    }

    .foot .btns {
        margin: 0 auto;
        gap: 10px;
    }

    .copyright span {
        font-size: 12px;
        display: block;
    }

    .appbox .left_box .subtitle {
        font-size: 2.6vw;
        line-height: 2.6vw;
        height: 2.6vw;
    }

    .foot_qrcode {
        width: 30vw;
        margin-left: -15vw;
    }

    .anticon svg {
        font-size: 2.66vw;
    }

    .mt70 {
        margin-top: 8vw;
    }

    .mt40 {
        margin-top: 4.875vw;
    }

    .mt50 {
        margin-top: 5.33vw;
    }



    .pd40 {
        padding: 0 4vw;
    }

    .mobile-login {
        display: flex;
    }

    .login-btn div {
        font-size: 12px;
    }

    .login-btn div img {
        height: 100% !important;
    }

    .login-btn {
        border-radius: 20px;
        padding: 5px;
    }

    .login-btn-box {
        padding-top: 2px;
        margin-right: 10px;
    }
}