.hightlight_box {
  flex: 1;
  height: 13vw;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 30px;
  height: 26vw;
  position: relative;
  user-select: none;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.single_box {
  width: 100%;
}

.teacher img {
  width: 100%;
  position: absolute;
  bottom: 160px;
}

.title_box {
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 40px;
  overflow: hidden;
  height: 160px;
  border-radius: 25px;
  opacity: 1;
  background: #2C2E35;
}

.title {
  font-size: 1.56vw;
  font-weight: 600;
  letter-spacing: 0px;
  color: #FFFFFF;
}

.subtitle {
  margin-top: 4px;
  font-size: 0.73vw;
  font-weight: 500;
  letter-spacing: 0px;
  color: #FFA516;
}


.content {
  margin-top: 10px;
  opacity: 0.4;
  font-size: 0.625vw;
  font-weight: 500;
  letter-spacing: 0px;

  color: #D8D8D8;
}

@media (max-width:1366px) {
  .teachers_box {
    margin-top: 60px;
  }

  .title_box {
    padding: 1.87vw 2vw;
    border-radius: 15px;
  }

  .teacher img {
    bottom: 100px;
  }
}

@media (max-width: 820px) {
  .hightlight_box {
    flex: auto;
    width: 37.67vw;
    height: 65.73vw;
  }

  .teacher {
    height: 65.73vw;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 15px;
  }

  .teacher img {
    bottom: 26vw;
  }

  .title_box {
    padding: 5.87vw 4vw;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
  }

  .title {
    font-size: 4vw;
    line-height: 4vw;

    margin-bottom: 1.6vw;

  }

  .subtitle {
    font-size: 2.4vw;
    margin-top: 0;
  }

}